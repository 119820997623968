<template>
  <div class="container">
    <h4 class="text-center my-4">Разница</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else-if="studentInfo&&Object.keys(studentInfo).length" class="my-3">


      <div class="text-center">
        <h5>
          {{studentInfo?.barcode}} {{studentInfo?.last_name}} {{studentInfo?.first_name}}
        </h5>
        <div>
          {{studentInfo?.educationProgram?.education_speciality_code}}
          {{studentInfo?.educationProgram?.education_speciality_name}}
          ({{studentInfo?.educationProgram?.admission_year}})
        </div>
      </div>

      <div class="my-3">
        <Button icon="pi pi-plus" label="Добавить дисциплину" class="p-button-rounded" @click="addNewDiscipline"/>
      </div>

      <div v-if="newDisciplines.length" class="mt-4">
        <div v-for="(d, dIndex) in newDisciplines" :key="dIndex" class="row mb-4">

          <div class="col-md-4">
            <Dropdown
              v-model="d.education_discipline_id"
              :options="d.disciplines"
              optionLabel="name"
              optionValue="id"
              placeholder="Выберите дисциплину"
              :filter="true"
              class="w100p"
              @filter="searchDiscipline($event.value, d)"/>
          </div>

          <div class="col-md-2">
            <Dropdown
              v-model="d.semester"
              :options="formationEducationProgram_form.educationSemesters"
              optionLabel="name"
              optionValue="id"
              class="w100p"
              placeholder="Семестр"/>
          </div>

          <div class="col-md-2">
            <InputText type="text" v-model="d.credit" placeholder="Кредит"/>
          </div>

          <div class="col-md-1">
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="deleteDiscipline(dIndex)"/>
          </div>

        </div>

        <div class="text-center">
          <Button icon="pi pi-save" label="Сохранить" class="p-button-rounded" @click="postDifference"/>
        </div>
      </div>

      <div v-if="differenceList.length" class="my-5">
        <DataTable :value="differenceList" showGridlines stripedRows>
          <Column header="№" :style="{width: '60px'}">
            <template #body="{index}">
              {{index+1}}
            </template>
          </Column>

          <Column header="Баркод" field="barcode"></Column>

          <Column header="ФИО">
            <template #body="{data}">
              {{data.last_name}} {{data.first_name}}
            </template>
          </Column>

          <Column header="ОП">
            <template #body="{data}">
              {{data.education_speciality_code}} {{data.education_speciality_name}} - {{data.op_language}}
            </template>
          </Column>

          <Column header="Дисциплина" field="education_discipline_name"></Column>
          <Column header="Семестр" field="semester"></Column>
          <Column header="Кредит" field="credit"></Column>

          <Column header="Оценка">
            <template #body="{data}">
              <input class="form-control" type="number" max="100" min="0" placeholder="Оценка" v-model="data.score">
            </template>
          </Column>

          <Column header="Преподаватель ставит оценку">
            <template #body="{data}">
              <Checkbox v-model="data.is_pps_score" :binary="true"/>
            </template>
          </Column>

          <Column header="Влияет на стипендию">
            <template #body="{data}">
              <Checkbox v-model="data.affect_scholarship" :binary="true"/>
            </template>
          </Column>

          <Column header="Сохранить">
            <template #body="{data}">
              <Button icon="pi pi-save" class="p-button-rounded" @click="putDifference(data.difference_ratings_id)"/>
            </template>
          </Column>

          <Column header="Удалить">
            <template #body="{data}">
              <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                      @click="deleteDifference(data.difference_ratings_id)"/>
            </template>
          </Column>

        </DataTable>
      </div>


    </div>

    <div v-else class="my-3 text-center">
      Студент не найден
    </div>

  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'


  export default {
    name: 'OfficeRegistrationStudentDifference',
    data() {
      return {
        loading: true,
        studentId: this.$route.query.id || 0,
        studentInfo: {},
        disciplines: [],
        newDisciplines: [],
        differenceList: [],
      }
    },
    computed: {
      ...mapState('formationEducationProgram', ['formationEducationProgram_form']),
    },
    methods: {
      ...mapActions('officeRegistrationDifference', ['GET_DIFFERENCE', 'POST_DIFFERENCE', 'PUT_DIFFERENCE', 'DELETE_DIFFERENCE']),
      ...mapActions('formationEducationProgram', ['GET_EDUCATION_SEMESTER']),
      ...mapActions('syllabus', ['SEARCH_DISCIPLINE']),
      ...mapActions('studentSchedule', ['GET_STUDENT_INFO']),

      addNewDiscipline() {
        this.newDisciplines.push({
          student_id: this.studentId,
          education_discipline_id: null,
          semester: null,
          credit: null,
          disciplines: []
        })
      },
      deleteDiscipline(dIndex) {
        this.newDisciplines.splice(dIndex, 1)
      },
      async searchDiscipline(name, discipline) {
        if (name.length > 3) {
          discipline.disciplines = await this.SEARCH_DISCIPLINE(name)
        }
      },
      async postDifference() {
        const form = this.newDisciplines.map(d => ({
          student_id: d.student_id,
          education_discipline_id: d.education_discipline_id,
          semester: d.semester,
          credit: d.credit,
        }))

        const res = await this.POST_DIFFERENCE(form)
        if (res) {
          this.newDisciplines = []
          await this.getDifferenceList()
          this.$message({title: 'Сохранено'})
        }
      },
      async putDifference(difference_ratings_id) {
        const form = this.differenceList.find(i => i.difference_ratings_id === difference_ratings_id)

        const res = await this.PUT_DIFFERENCE({
          id: form.difference_ratings_id,
          score: form.score,
          is_pps_score: form.is_pps_score ? 1 : 0,
          affect_scholarship: form.affect_scholarship ? 1 : 0
        })

        if (res) {
          await this.getDifferenceList()
          this.$message({title: 'Сохранено'})
        }
      },
      async deleteDifference(difference_ratings_id) {
        const res = await this.DELETE_DIFFERENCE(difference_ratings_id)
        if (res) {
          await this.getDifferenceList()
          this.$message({title: 'Удалено'})
        }
      },
      async getDifferenceList() {
        const differenceList = await this.GET_DIFFERENCE(this.studentId)
        differenceList.forEach(d => {
          d.is_pps_score = !!(+d.is_pps_score)
          d.affect_scholarship = !!(+d.affect_scholarship)
        })
        this.differenceList = differenceList
      }
    },
    async mounted() {
      if (this.studentId) {
        await this.GET_EDUCATION_SEMESTER()
        this.studentInfo = await this.GET_STUDENT_INFO(this.studentId)
        await this.getDifferenceList()
      }

      this.loading = false
    },
  }

</script>
